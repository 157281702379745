import React, { useState, useEffect } from 'react'
import { UseAuth } from '../store/auth'
import AddTeamLead from './AddTeamLead'
import { Doughnut } from 'react-chartjs-2'
import profileimage from '../profileimage.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Add from '../images/Add.png'
import Remove from '../images/Remove.png'
import { FaDirections, FaEye } from 'react-icons/fa'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import AddTeamMembersPopup from './AddTeamMembersPopup' // Import the popup component
import AddProjectPopup from './AddProjectPopup'
import TeammemberShow from './TeammemberShow' // Import the team member display component

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import '../App.css'
import TeamLeadshow from './TeamLeadshow'
ChartJS.register(ArcElement, Tooltip, Legend)

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY

const ProjectManagerDashboard = () => {
  const { authorizationToken, user } = UseAuth()
  const [projectDetails, setProjectDetails] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [tasks, setTasks] = useState([])
  const [userTasks, setUserTasks] = useState([])
  const [showAddTeamForm, setShowAddTeamForm] = useState(false)
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [showAddPopup, setShowAddPopup] = useState(false)
  const [ShowTeamMembersPopup, setShowTeamMembersPopup] = useState(false)
  const [teammemembers, setteammembers] = useState([])
  const [showTeamLeadPopup, setShowTeamLeadPopup] = useState(false)
  const [newdeadline, setnewDeadline] = useState(new Date())
  const [showExtendForm, setShowExtendForm] = useState(false)
  const [reason, setReason] = useState('')

  const handleAddClick = () => setShowAddPopup(true)
  const handlePopupClosee = () => setShowAddPopup(false)
  const handleShowTeamLeadPopup = () => setShowTeamLeadPopup(true)
  const handleCloseTeamLeadPopup = () => setShowTeamLeadPopup(false)
  const userids = user._id
  const uniqueUsers = []
  const userMap = new Map()

  const handleAddTeamClick = (projectId) => {
    setSelectedProjectId(projectId)
    setShowAddTeamForm(true)
  }

  const handlePopupClose = () => {
    setShowAddTeamForm(false)
    setSelectedProjectId(null)
  }

  const handleTeamUpdate = () => {
    fetchProjectDetails() // Refresh the project details after team update
  }

  tasks.forEach((task) => {
    if (task.assignedUsers && Array.isArray(task.assignedUsers)) {
      task.assignedUsers.forEach((user) => {
        const uniqueKey = user.name
        if (!userMap.has(uniqueKey)) {
          userMap.set(uniqueKey, true)
          uniqueUsers.push(user)
        }
      })
    }
  })

  const fetchSelectedUsers = async (selectedProjectId) => {
    try {
      const response = await fetch(
        `${API_URL}/${selectedProjectId}/selectedUsers`,
        {
          method: 'GET',
          headers: {
            'x-api-key': API_KEY,
            Authorization: authorizationToken,
          },
        }
      )
      const users = await response.json()
      setteammembers(users)
    } catch (error) {
      console.error('Error fetching selected users:', error)
    }
  }

  const fetchProjectDetails = async () => {
    try {
      const response = await fetch(
        `${API_URL}/projects/assigned-to/${userids}`,
        {
          method: 'GET',
          headers: {
            'x-api-key': API_KEY,
            Authorization: authorizationToken,
          },
        }
      )
      const data = await response.json()
      if (response.ok && data.length > 0) {
        setProjectDetails(data)
      } else {
        setProjectDetails([]) // Clear the project details
        toast.error('No projects found. Please contact the superadmin.')
      }
    } catch (error) {
      console.log('Error fetching project details:', error)
    }
  }
  useEffect(() => {
    fetchProjectDetails()
  }, [])
  useEffect(() => {
    if (selectedProjectId) {
      fetchSelectedUsers(selectedProjectId)
    }
  }, [selectedProjectId, handleTeamUpdate])

  const handleProjectSelect = async (projectId) => {
    if (!projectId) {
      return
    }
    setSelectedProjectId(projectId) // Ensure this is setting the selected project ID
    try {
      const response = await axios.get(`${API_URL}/api/projects/${projectId}`)
      setSelectedProject(response.data.project)
      setTasks(response.data.tasks)
      setSelectedUser(null) // Reset selected user when project changes
      setUserTasks([])
    } catch (error) {
      console.error('Error fetching project details:', error)
    }
  }

  const handleUserClick = (user, index) => {
    setSelectedIndex(selectedIndex === index ? null : index)
    if (selectedIndex === index) {
      setSelectedUser(null)
      setUserTasks([])
    } else {
      setSelectedUser(user)
      const filteredTasks = tasks.filter((task) =>
        task.assignedUsers.some(
          (assignedUser) => assignedUser.name === user.name
        )
      )
      setUserTasks(filteredTasks)
    }
  }

  const getTaskCounts = () => {
    const statusCounts = {
      Backlog: 0,
      InProgress: 0,
      InReview: 0,
      QA: 0,
      Done: 0,
      total: 0,
    }

    tasks.forEach((task) => {
      if (task.status in statusCounts) {
        statusCounts[task.status]++
      }
      statusCounts.total++
    })

    return statusCounts
  }

  const taskCounts = getTaskCounts()

  const projectStatusData = {
    labels: ['Backlog', 'In Progress', 'In Review', 'QA (Testing)', 'Done'],
    datasets: [
      {
        data: [
          taskCounts.Backlog,
          taskCounts.InProgress,
          taskCounts.InReview,
          taskCounts.QA,
          taskCounts.Done,
        ],
        backgroundColor: [
          '#0065FF',
          '#FFAB00',
          '#00B8D9',
          '#FF5630',
          '#4CAF50',
        ],
        borderWidth: 1,
        borderColor: '#ffffff',
      },
    ],
  }

  const projectStatusOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: '35%',
  }
  const handleAssignTeamLead = async (selectedProjectId, selectedLeads) => {
    if (!selectedProjectId || !selectedLeads.length) {
      toast.error('Invalid project ID or team leads.')
      return
    }

    try {
      const response = await fetch(
        `${API_URL}/api/projects/${selectedProjectId}/teamleads`, // Ensure proper URL
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ teamLeads: selectedLeads }), // Correct request body
        }
      )

      const result = await response.json()

      if (response.ok) {
        toast.success(result.message)
      } else {
        toast.error(result.message || 'Failed to assign team leads.')
      }
    } catch (error) {
      toast.error('An error occurred.')
    }
  }

  const handleRemoveMember = async (member, selectedProjectId) => {
    try {
      const response = await axios.post(`${API_URL}/remove-selected-user`, {
        projectId: selectedProjectId,
        userId: member._id,
      })
      // Update the UI
      setteammembers((prevMembers) =>
        prevMembers.filter((m) => m._id !== member._id)
      )
      toast.success(response.data.message)
    } catch (error) {
      console.error(
        'Error removing member:',
        error.response?.data || error.message
      )
      toast.error('Failed to remove the member.')
    }
  }

  const submitExtensionRequest = async (projectId) => {
    try {
      const response = await fetch(`${API_URL}/projects/extend/${projectId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          newDeadline: newdeadline,
          reason,
        }),
      })
      const data = await response.json()
      if (response.ok) {
        toast.success('Extension request submitted for approval!')
        setReason('')
        setShowExtendForm(false)
        fetchProjectDetails() // Fetch updated project details
      } else {
        toast.error(`Error: ${data.message}`)
      }
    } catch (error) {
      console.log('Error submitting extension request:', error)
    }
  }
  return (
    <div className="dashboard-container">
      <ToastContainer />
      <div className="row">
        <div className="col-md-4">
          <h3 className="ProList">Projects List</h3>
          <div className="abc">
            <button
              className="btn btn-dark mx-1"
              style={{ backgroundColor: '#1B2D50' }}
              onClick={handleAddClick}
            >
              {/* <i className="bi bi-plus-lg" ></i> */}
              <img
                src={Add}
                alt="Add icon"
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '5px',
                  marginBottom: '3px',
                }}
              />
              Add new Project
            </button>

            <button
              className="pqr"
              data-bs-toggle="modal"
              data-bs-target="#extensionRequestModal"
            >
              Extend Deadline
            </button>
            <div
              className="modal fade"
              id="extensionRequestModal"
              tabIndex="-1"
              aria-labelledby="extensionRequestModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="extensionRequestModalLabel">
                      Submit Extension Request
                    </h5>
                    <button
                      className="canclerequest"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      ✖
                    </button>
                  </div>

                  <div className="modal-body">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        submitExtensionRequest(selectedProjectId)
                      }}
                    >
                      <div className="mb-3">
                        <label htmlFor="extend-reason" className="form-label">
                          Reason for Extension:
                        </label>
                        <textarea
                          id="extend-reason"
                          className="form-control"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="new-deadline" className="form-label">
                          New Deadline:
                        </label>
                        <DatePicker
                          id="new-deadline"
                          selected={newdeadline}
                          onChange={(date) => setnewDeadline(date)}
                          showTimeSelect
                          placeholderText="Select new deadline"
                          className="form-control"
                          dateFormat="dd/MM/yyyy p"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary custom-btn mx-2"
                        data-bs-dismiss="modal"
                      >
                        Submit Extension Request
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showAddPopup && <AddProjectPopup onClose={handlePopupClosee} />}
          <select
            className="ProList"
            onChange={(e) => handleProjectSelect(e.target.value)}
          >
            <option value="">-- Select a Project --</option>
            {projectDetails.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectname}
              </option>
            ))}
          </select>

          <div className="dashboard-grid">
            <div className="project-status">
              <div className="col-md-4">
                <h4 className="ProStatus">Project Status</h4>

                <p className="ProDuration">
                  Project Duration: {'  '}
                  {selectedProject
                    ? new Date(selectedProject.startDate).toLocaleDateString(
                        'en-gb'
                      ) + ' '
                    : '--'}{' '}
                  to{' '}
                  {selectedProject
                    ? ' ' +
                      new Date(selectedProject.deadline).toLocaleDateString(
                        'en-gb'
                      )
                    : '--'}
                </p>

                <p className="ToTask">Total Tasks: {taskCounts.total}</p>
                <div className="canvas">
                  <Doughnut
                    data={projectStatusData}
                    options={projectStatusOptions}
                  />
                </div>
                <div className="datarepre">
                  <ul className="status-list">
                    <li className="backlog">
                      <span className="square"></span> Backlog{' '}
                      <span className="numericnumber">
                        {taskCounts.Backlog}
                      </span>
                    </li>
                    <li className="in-progress">
                      <span className="square"></span> In Progress{' '}
                      <span className="numericnumber">
                        {taskCounts.InProgress}
                      </span>
                    </li>
                    <li className="in-review">
                      <span className="square"></span> In Review{' '}
                      <span className="numericnumber">
                        {taskCounts.InReview}
                      </span>
                    </li>
                    <li className="qa">
                      <span className="square"></span> QA (Testing){' '}
                      <span className="numericnumber">{taskCounts.QA}</span>
                    </li>
                    <li className="Done">
                      <span className="square"></span> Done{' '}
                      <span className="numericnumber">{taskCounts.Done}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <h3 className="atl">Assign Task List</h3>
          <div className="assignee-task">
            <div className="iop ">
              <h4>
                {/* Add Team Members{" "} */}
                <button
                  title="Add Teammembers"
                  className="AddTeamMember"
                  onClick={() => handleAddTeamClick(selectedProjectId)}
                >
                  <img
                    src={Add}
                    alt="Add icon"
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '5px',
                      marginBottom: '3px',
                    }}
                  />
                  Add Team Members
                </button>
                <button
                  className="btn  mx-2 "
                  title=" Team Members"
                  onClick={() => setShowTeamMembersPopup(true)}
                  style={{
                    color: '#fff',
                    width: '205px',
                    height: '50px',
                    backgroundColor: '#FF2D55',
                  }}
                >
                  <img
                    src={Remove}
                    alt="Remove icon"
                    style={{
                      width: '16px',
                      height: '16px',
                      marginRight: '5px',
                      marginBottom: '3px',
                    }}
                  />{' '}
                  Remove Members
                </button>
                {showAddTeamForm && (
                  <AddTeamMembersPopup
                    projectId={selectedProjectId}
                    onClose={handlePopupClose}
                    onTeamUpdate={handleTeamUpdate}
                  />
                )}
                {ShowTeamMembersPopup && (
                  <TeammemberShow
                    teamMembers={teammemembers}
                    onClose={() => setShowTeamMembersPopup(false)}
                    onRemoveMember={handleRemoveMember}
                    projectId={selectedProjectId}
                  />
                )}
              </h4>
              <div className="addateamlead">
                <div>
                  <AddTeamLead
                    onAssignTeamLead={handleAssignTeamLead}
                    projectid={selectedProjectId}
                  />
                </div>
                <div>
                  <button
                    className="btn mx-1 "
                    title="View Team Leads"
                    style={{
                      color: '#fff',
                      width: '205.74px',
                      height: '50px',
                      backgroundColor: '#FF2D55',
                      fontFamily: 'Roboto',
                    }}
                    onClick={handleShowTeamLeadPopup}
                  >
                    {/* <FaEye style={{ color: 'white' }} /> */}
                    <img
                      src={Remove}
                      alt="Remove icon"
                      style={{
                        width: '16px',
                        height: '16px',
                        marginRight: '5px',
                        marginBottom: '3px',
                      }}
                    />
                    Remove Team Lead
                  </button>

                  {showTeamLeadPopup && (
                    <TeamLeadshow
                      projectId={selectedProjectId}
                      onClose={handleCloseTeamLeadPopup}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="image-container">
              {uniqueUsers.length > 0 ? (
                uniqueUsers.map((user, index) => {
                  const imageUrl = user.profileImage
                    ? `${API_URL}${user.profileImage}`
                    : `${profileimage}`
                  return (
                    <div
                      key={user.name || index}
                      className={`image-card ${
                        selectedIndex === index ? 'selected' : ''
                      }`}
                      onClick={() => handleUserClick(user, index)}
                    >
                      <div className="image-wrapper">
                        <img
                          src={imageUrl}
                          alt={user.name || 'Profile'}
                          className="profile-image"
                        />
                      </div>
                      <div
                        className={`name-overlay ${
                          selectedIndex === index ? 'visible' : ''
                        }`}
                      >
                        {user.name || 'Unknown User'}
                      </div>
                    </div>
                  )
                })
              ) : (
                <p className="Nouser">No users assigned</p>
              )}
            </div>
          </div>
          {/* <div className="project-table-container">
            <h3 className="project-table-title">
              {selectedUser
                ? `Tasks for ${selectedUser.name}`
                : 'Project Details'}
            </h3>

            <table className="custom-table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {(selectedUser ? userTasks : tasks)
                  .sort((a, b) => new Date(b.deadline) - new Date(a.deadline))
                  .slice(0, 10) // Limit to 10 tasks
                  .map((task, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/admin/taskListdetails?highlight=${task._id}`}
                          className="custom-link"
                        >
                          {task.task}
                        </Link>
                      </td>
                      <td>{task.status}</td>
                      <td>
                        {task
                          ? new Date(task.deadline).toLocaleDateString('en-gb')
                          : '--'}
                      </td>
                    </tr>
                  ))}
                   {(selectedUser ? userTasks : tasks).length > 10 && (
              <div className="see-more-container">
                <button
                  className="see-more-button"
                  onClick={() => (window.location.href = 'taskListdetails')}
                >
                  See More
                </button>
              </div>
            )}
              </tbody>
            </table>
          </div> */}

          <div className="project-table-container">
            <h3 className="project-table-title">
              {selectedUser
                ? `Tasks for ${selectedUser.name}`
                : 'Project Details'}
            </h3>

            <table className="custom-table">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Task Name</th>
                  <th>Status</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {(selectedUser ? userTasks : tasks)
                  .sort((a, b) => new Date(b.deadline) - new Date(a.deadline))
                  .slice(0, 10) // Limit to 10 tasks
                  .map((task, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={`/admin/taskListdetails?highlight=${task._id}`}
                          className="custom-link"
                        >
                          {task.task}
                        </Link>
                      </td>
                      <td>{task.status}</td>
                      <td>
                        {task
                          ? new Date(task.deadline).toLocaleDateString('en-gb')
                          : '--'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {(selectedUser ? userTasks : tasks).length > 10 && (
              <div className="seemorebtn">
                <button
                  className="float-right btn btn-primary"
                  onClick={() =>
                    (window.location.href = `/admin/taskListdetails?user=${
                      selectedUser ? selectedUser.id : ''
                    }`)
                  }
                >
                  See More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectManagerDashboard
