



import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import profileimage from "../profileimage.png";
 
const TeamLeadShow = ({ projectId, onClose }) => {
  const [teamLeads, setTeamLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
 
 
  const fetchTeamLeads = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/projects/${projectId}/teamleads`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      setTeamLeads(response.data.teamLeads);
    } catch (error) {
      console.error("Error fetching team leads:", error);
      toast.error("Failed to fetch team leads.");
    }
  };
 
  const handleRemoveLead = async (leadId) => {
    try {
      const response = await axios.post(
        `${API_URL}/projects/${projectId}/removeTeamLead`,
        { leadId },
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
 
      toast.success(response.data.message);
      setTeamLeads((prevLeads) =>
        prevLeads.filter((lead) => lead._id !== leadId)
      );
    } catch (error) {
      console.error("Error removing team lead:", error);
      toast.error("Failed to remove team lead.");
    }
  };
 
  useEffect(() => {
    if (projectId) {
      fetchTeamLeads();
    }
  }, [projectId]);
 
  const filteredLeads = teamLeads.filter((lead) =>
    lead.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <Modal
      show
      onHide={onClose}
      centered
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Team Leads</Modal.Title>
      </Modal.Header>
 
      <Modal.Body>
        {/* Search Input */}
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search team leads"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
 
        {/* Grid Layout for Team Leads */}
        {filteredLeads.length > 0 ? (
          <div
            className="team-leads-grid"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <Row className="g-3">
              {filteredLeads.map((lead) => {
                const imageUrl = lead.profileImage
                  ? `${API_URL}${lead.profileImage}`
                  : profileimage; // Default placeholder image
 
                return (
                  <Col
                    key={lead._id}
                    xs={6}
                    sm={4}
                    md={3}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className="team-lead-card text-center p-2 position-relative"
                      style={{
                        border: "1px solid #ddd",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        transition: "transform 0.2s",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      {/* Remove Button */}
                      <Button
                        variant="link"
                        onClick={() => handleRemoveLead(lead._id)}
                        className="p-0 position-absolute"
                        style={{
                          color: "red",
                          top: "5px",
                          right: "5px",
                        }}
                      >
                        <FaMinusCircle size={18} />
                      </Button>
 
                      {/* Profile Image */}
                      <img
                        src={imageUrl}
                        alt={lead.name}
                        className="rounded-circle mb-2"
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          border: "2px solid #ddd",
                        }}
                      />
 
                      {/* Lead Name */}
                      <p
                        className="mb-1"
                        style={{
                          fontSize: "0.9rem",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {lead.name}
                      </p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <p className="text-center text-muted">No team leads found.</p>
        )}
      </Modal.Body>
 
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
 
export default TeamLeadShow;