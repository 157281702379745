
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { UseAuth } from "../store/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddTeamMembersPopup = ({ projectId, onClose, onTeamUpdate }) => {
  const { authorizationToken } = UseAuth();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [error, setError] = useState("");

  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;

  // Fetch all available users
  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch(`${API_URL}/admin/taskToUser`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch users data.");
      }

      const data = await response.json();
      setUsers(
        data.map((user) => ({ value: user._id, label: user.email, ...user }))
      );
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }
  }, [API_URL, API_KEY, authorizationToken]);

  // Add selected team members to the project
  const handleAddTeamMembers = async () => {
    if (!projectId) {
      toast.error("Project ID is missing. Please select a project.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}/projects/${projectId}/team`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
          Authorization: authorizationToken,
        },
        body: JSON.stringify({
          selectedUser: selectedUsers.map((user) => user.value),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success("Team members added successfully!");
        onTeamUpdate();
        onClose();
      } else {
        throw new Error(data.message || "Failed to add team members.");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (projectId) fetchUsers();
  }, [projectId, fetchUsers]);

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Team Members</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            {error && <p className="text-danger">{error}</p>}
            <Select
              isMulti
              options={users}
              value={selectedUsers}
              onChange={setSelectedUsers}
              placeholder="Select users..."
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleAddTeamMembers}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddTeamMembersPopup;
