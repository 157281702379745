
import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { FaMinusCircle } from "react-icons/fa";
import profileimage from "../profileimage.png";
 
const TeamMemberShow = ({
  teamMembers,
  onClose,
  onRemoveMember,
  projectId,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
 
  const filteredMembers = teamMembers.filter((member) =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <Modal
      show
      onHide={onClose}
      centered
      size="xl"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Team Members</Modal.Title>
      </Modal.Header>
 
      <Modal.Body>
        {/* Search Input */}
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search team members"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Form.Group>
 
        {/* Grid Layout for Team Members */}
        {filteredMembers.length > 0 ? (
          <div
            className="team-members-grid"
            style={{
              maxHeight: "500px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <Row className="g-3">
              {filteredMembers.map((member) => {
                const imageUrl = member.profileImage
                  ? `${process.env.REACT_APP_API_URL}${member.profileImage}`
                  : profileimage; // Default placeholder image
 
                return (
                  <Col
                    key={member._id}
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className=" text-center p-2"
                      style={{
                       
                       
                        transition: "transform 0.2s",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.transform = "scale(1.05)")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    >
                      {/* Remove Button */}
                      <Button
                        variant="link"
                        onClick={() => onRemoveMember(member, projectId)}
                        className="p-0 position-absolute"
                        style={{ color: "red", right: "10px", top: "10px" }}
                      >
                        <FaMinusCircle size={18} />
                      </Button>
 
                      {/* Profile Image */}
                      <img
                        src={imageUrl}
                        alt={member.name}
                        className="rounded-circle mb-2"
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          border: "2px solid #ddd",
                        }}
                      />
 
                      {/* Member Name */}
                      <p
                        className="mb-1"
                        style={{
                         fontSize:"14px",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                      >
                        {member.name}
                      </p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <p className="text-center text-muted">No team members found.</p>
        )}
      </Modal.Body>
 
    </Modal>
  );
};
 
export default TeamMemberShow;