import React, { useState } from 'react'
import { UseAuth } from '../store/auth'

const API_URL = process.env.REACT_APP_API_URL
const API_KEY = process.env.REACT_APP_API_KEY

const AddProjectPopup = ({ onClose, onProjectAdded }) => {
  const { user } = UseAuth()

  const userId = user._id
  const [projectData, setProjectData] = useState({
    projectname: '',
    description: '',
    startDate: '',
    deadline: '',
    projectManager: userId || '', // Default to the logged-in user's ID
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setProjectData({ ...projectData, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch(`${API_URL}/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Optional: if your API requires a key
        },
        body: JSON.stringify(projectData),
      })
      const result = await response.json()
      if (response.ok) {
        onClose()
      } else {
        alert(result.error)
      }
    } catch (err) {
      console.error(err)
      alert('Error creating project')
    }
  }

  return (
    <div className="modal show d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Project</h5>
            <button
              // className="close"
              type="button"
              className="btn-close closebuttons"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="projectname" className="form-label">
                  Project Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="projectname"
                  name="projectname"
                  value={projectData.projectname}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={projectData.description}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <label htmlFor="startDate" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="startDate"
                  name="startDate"
                  value={projectData.startDate}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="deadline" className="form-label">
                  Deadline
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="deadline"
                  name="deadline"
                  value={projectData.deadline}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Add Project
                </button>
                {/* <button type="button" className="btn btn-secondary" onClick={onClose}>
                  Cancel
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddProjectPopup
