import React, { useState, useEffect } from "react";
import Add from '../images/Add.png'

const AddTeamLead = ({ onAssignTeamLead, projectid }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [teamLeads, setTeamLeads] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  // Fetch users with "Team Lead" role
  useEffect(() => {
    const fetchTeamLeads = async () => {
      try {
        const response = await fetch(`${API_URL}/users/adminsget`);
        const data = await response.json();
        console.log("Fetched Team Leads:", data.users); // Debug log
        setTeamLeads(data.users || []);
      } catch (err) {
        console.error("Error fetching team leads:", err);
      }
    };

    if (showPopup) {
      fetchTeamLeads();
    }
  }, [showPopup, API_URL]);

  const handleSelectLead = (id) => {
    setSelectedLeads((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((leadId) => leadId !== id)
        : [...prevSelected, id]
    );
  };
  const handleAssign = () => {
    if (selectedLeads.length === 0) {
      alert("Please select at least one team lead.");
      return;
    }
    onAssignTeamLead(projectid, selectedLeads); // Ensure `selectedProjectId` is valid
    setShowPopup(false);
  };

  return (
    <div>
      <h4>
        
        <button
          className="btn btn-dark add-team-member-btn mx-1"
          onClick={() => setShowPopup(true)}
          title="Add Team Lead"
          style={{ color: '#fff',width:'205.74px',height:'50px' ,backgroundColor:'#1B2D50'}}
        >
          <img src={Add} alt="Add icon" style={{ width: '16px', height: '16px',marginRight:'5px',marginBottom:'3px' }} /> Add Team Lead
        </button>
      </h4>

      {showPopup && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Team Leads</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowPopup(false)}
                ></button>
              </div>
              <div className="modal-body">
                <ul className="list-group">
                  {teamLeads.map((user) => (
                    <li
                      key={user._id}
                      className={`list-group-item ${
                        selectedLeads.includes(user._id) ? "active" : ""
                      }`}
                      onClick={() => handleSelectLead(user._id)}
                      style={{ cursor: "pointer" }}
                    >
                      {user.name} ({user.email})
                    </li>
                  ))}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleAssign}
                >
                  Assign
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowPopup(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTeamLead;
